import { useEffect, useState } from "react";
import Modal from "components/Modal";
import API from "config/configs";
import style from "./style.module.scss";
import checkIsLogged from "components/IsLogged";

const Funcionarios = () => {
  const [lista, setLista] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [mensagemModal, setMensagemModal] = useState({});
  const [idDeleted, setIdDeleted] = useState("");
  const [nomeEdit, setNomeEdit] = useState("");
  const [inicioEdit, setInicioEdit] = useState("");
  const [finalEdit, setFinalEdit] = useState("");
  const [inicioAlmocoEdit, setInicioAlmocoEdit] = useState("");
  const [finalAlmocoEdit, setFinalAlmocoEdit] = useState("");
  const [listaServicos, setListaServicos] = useState([]);
  const [listaServicosUpdate, setListaServicosUpdate] = useState([]);
  const [diaSemana, setDiaSemana] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const checkIsAdmin = async () => {
    const isLoggedIn = JSON.parse(localStorage.getItem("isLogged") || "null");
    if (!isLoggedIn) return;

    const response = await checkIsLogged();
    const isAdminUser = response.some(
      (user) =>
        user.usuario === isLoggedIn.login &&
        user.senha === isLoggedIn.senha &&
        user.usuario === "lksribeiro2014@gmail.com"
    );

    if (isAdminUser) {
      setIsAdmin(true);
    }
  };

  const getServicos = () => {
    fetch(`${API}/agendamentos/servicos`)
      .then((res) => res.json())
      .then((servicos) => {
        let listaTemp = [];
        let listaServicos = servicos;

        listaServicos.map((servico) => {
          listaTemp.push(servico);
        });

        setListaServicos(listaTemp);
      });
  };

  const getFuncionarios = () => {
    let listaTemp = [];

    fetch(`${API}/agendamentos/funcionarios`)
      .then((res) => res.json())
      .then((funcionarios) => {
        funcionarios.map((item, index) => {
          listaTemp.push(item);

          if (item.servicos) {
            let servicos = JSON.parse(item.servicos);
            let listaServicos = [];

            servicos.map((servico) => {
              listaServicos.push(servico);
              item.servicos = JSON.stringify(listaServicos);
            });
          }
        });

        setLista(listaTemp);
      });
  };

  const selectDiaSemana = (e) => {
    if (e.target.value !== "") {
      setDiaSemana(e.target.value);
    }

    // setando horarios nos inputs para edição
    lista.map((funcionario) => {
      if (funcionario.nomeFuncionario === nomeEdit) {
        if (funcionario[e.target.value]) {
          let horarios = JSON.parse(funcionario[e.target.value]);

          horarios.map((hora) => {
            setInicioEdit(hora.horarioInicio);
            setFinalEdit(hora.horarioFinal);
            setInicioAlmocoEdit(hora.horarioInicioAlmocoFuncionario);
            setFinalAlmocoEdit(hora.horarioFinalAlmocoFuncionario);
          });
        } else {
          setInicioEdit("");
          setFinalEdit("");
          setInicioAlmocoEdit("");
          setFinalAlmocoEdit("");
        }
      }
    });
  };

  const handleNomeEdit = (e) => setNomeEdit(e.target.value);
  const handleInicioEdit = (e) => setInicioEdit(e.target.value);
  const handleFinalEdit = (e) => setFinalEdit(e.target.value);
  const handleInicioAlmocoEdit = (e) => setInicioAlmocoEdit(e.target.value);
  const handleFinalAlmocoEdit = (e) => setFinalAlmocoEdit(e.target.value);

  const limparServicosSelecionados = () => {
    let listaFilter = listaServicos;

    listaFilter.map((itemServico) => {
      itemServico.selecionado = "";
    });

    setListaServicos(listaFilter);

    let listaElem = document.querySelectorAll(".servicosFuncionario li");
    listaElem.forEach((elem) => {
      elem.classList.remove("selecionado");
    });
  };

  const selecionaServico = (e) => {
    let nome = e.target.dataset.name;

    document
      .querySelector(`[data-name="${nome}"]`)
      .classList.add("selecionado");

    let newListaSelecionados = listaServicos
      .map((item) => {
        if (item.servico === nome || item.selecionado === "selecionado") {
          item.selecionado = "selecionado";
          return item;
        }
      })
      .filter((item) => item !== undefined);

    setListaServicosUpdate(newListaSelecionados);
  };

  const editarFuncionario = (e) => {
    let id = e.target.id;
    let nome = e.target.dataset.nome;
    let inicio = e.target.dataset.inicio;
    let final = e.target.dataset.final;
    let inicioAlmoco = e.target.dataset.inicioalmoco;
    let finalAlmoco = e.target.dataset.finalalmoco;
    let servicos = e.target.dataset.servicos;
    let listaFilter = listaServicos;

    listaFilter.map((itemServico) => {
      itemServico.selecionado = "";
    });

    listaFilter.map((itemServico) => {
      JSON.parse(servicos).map((item) => {
        if (itemServico.servico === item.servico) {
          itemServico.selecionado = "selecionado";
        }
      });
    });

    setInicioEdit(" ");
    setFinalEdit(" ");
    setInicioAlmocoEdit(" ");
    setFinalAlmocoEdit(" ");

    if (document.getElementById("inicioEdit"))
      document.getElementById("inicioEdit").value = "";
    if (document.getElementById("finalEdit"))
      document.getElementById("finalEdit").value = "";
    if (document.getElementById("inicioAlmocoEdit"))
      document.getElementById("inicioAlmocoEdit").value = "";
    if (document.getElementById("finalAlmocoEdit"))
      document.getElementById("finalAlmocoEdit").value = "";
    if (document.getElementById("diaSemana"))
      document.getElementById("diaSemana").value = "";

    setNomeEdit(nome);
    setInicioEdit(inicio);
    setFinalEdit(final);
    setInicioAlmocoEdit(inicioAlmoco);
    setFinalAlmocoEdit(finalAlmoco);
    setIdDeleted(id);
    setListaServicos(listaFilter);
    setShowEdit(true);

    let listTempSelecteds = [];
    listaFilter.map((item) => {
      if (item.selecionado === "selecionado") {
        listTempSelecteds.push(item);
      }
    });

    setListaServicosUpdate(listTempSelecteds);
  };

  const salvarEdicao = (e) => {
    let id = e.target.dataset.edit;
    let dia = diaSemana;
    let segunda = "",
      terca = "",
      quarta = "",
      quinta = "",
      sexta = "",
      sabado = "",
      domingo = "";

    let listaHorarios = JSON.stringify([
      {
        horarioInicio: inicioEdit,
        horarioFinal: finalEdit,
        horarioInicioAlmocoFuncionario: inicioAlmocoEdit,
        horarioFinalAlmocoFuncionario: finalAlmocoEdit,
      },
    ]);

    if (dia === "segunda") segunda = listaHorarios;
    if (dia === "terca") terca = listaHorarios;
    if (dia === "quarta") quarta = listaHorarios;
    if (dia === "quinta") quinta = listaHorarios;
    if (dia === "sexta") sexta = listaHorarios;
    if (dia === "sabado") sabado = listaHorarios;
    if (dia === "domingo") domingo = listaHorarios;

    let body = {
      id: id,
      nomeFuncionario: nomeEdit,
      segunda: segunda,
      terca: terca,
      quarta: quarta,
      quinta: quinta,
      sexta: sexta,
      sabado: sabado,
      domingo: domingo,
      servicos: JSON.stringify(listaServicosUpdate),
    };

    fetch(`${API}/funcionario/edit`, {
      method: "put",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
      async: false,
    });

    setMensagemModal({
      text: "Edição realizada com sucesso",
      status: "sucesso",
    });

    setTimeout(function () {
      getFuncionarios();
    }, 1000);

    setShowEdit(false);
  };

  const deletarFuncionario = (e) => {
    let id = e.target.id;
    let name = e.target.name;

    let body = {
      id: id,
    };

    fetch(`${API}/agendamentos/funcionarios/deletar`, {
      method: "delete",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
      async: false,
    })
      .then((response) => response.json())
      .then((json) => {
        setIdDeleted(id);
        console.log("Dados removidos com sucesso", json);
        setMensagemModal({
          text: `Funcionário "${name}" removido com sucesso`,
          status: "sucesso",
        });

        setShowEdit(false);

        setTimeout(function () {
          getFuncionarios();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkIsAdmin();
    getServicos();
    getFuncionarios();
  }, []);

  useEffect(() => {
    getFuncionarios();
  }, [idDeleted]);

  return (
    <main className={style.tabelaPrecos}>
      {isAdmin && (
        <>
          <Modal mensagem={mensagemModal} />
          <h2 className="title">Tabela de Funcionários</h2>

          <div className={style.wrapTable}>
            <table>
              <thead>
                <tr>
                  <th>
                    Nome do <br /> Funcionário
                  </th>
                  <th>Dias de trabalho</th>
                  <th>Serviços</th>
                  <th>Editar</th>
                  <th>Deletar</th>
                </tr>
              </thead>
              <tbody>
                {lista.map((funcionario, index) => {
                  let dias = [];
                  let segunda = JSON.parse(funcionario.segunda);
                  let terca = JSON.parse(funcionario.terca);
                  let quarta = JSON.parse(funcionario.quarta);
                  let quinta = JSON.parse(funcionario.quinta);
                  let sexta = JSON.parse(funcionario.sexta);
                  let sabado = JSON.parse(funcionario.sabado);
                  let domingo = JSON.parse(funcionario.domingo);

                  if (segunda !== null) dias.push("Segunda");
                  if (terca !== null) dias.push("Terça");
                  if (quarta !== null) dias.push("Quarta");
                  if (quinta !== null) dias.push("Quinta");
                  if (sexta !== null) dias.push("Sexta");
                  if (sabado !== null) dias.push("Sábado");
                  if (domingo !== null) dias.push("Domingo");

                  return (
                    <>
                      <tr key={index} id={funcionario.id}>
                        <td>{funcionario.nomeFuncionario}</td>
                        <td>{dias.join(", ")}</td>
                        <td>
                          <ul>
                            {listaServicos.map(
                              (item) =>
                                funcionario.servicos.includes(item.servico) && (
                                  <li>{item.servico}</li>
                                )
                            )}
                          </ul>
                        </td>
                        <td>
                          <button
                            className="button editar"
                            data-nome={funcionario.nomeFuncionario}
                            data-servicos={funcionario.servicos}
                            id={funcionario.id}
                            onClick={editarFuncionario}
                          >
                            Editar
                          </button>
                        </td>
                        <td>
                          <button
                            className="button deletar"
                            name={funcionario.nomeFuncionario}
                            id={funcionario.id}
                            onClick={deletarFuncionario}
                          >
                            Deletar
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          {showEdit && (
            <div className={style.formEdicao}>
              <div className={style.fieldSeparador}>
                <label htmlFor="nomeEdit">Nome:</label>
                <input
                  type="text"
                  className=""
                  id="nomeEdit"
                  value={nomeEdit}
                  onChange={handleNomeEdit}
                />
              </div>
              <div className={style.fieldSeparador}>
                <label htmlFor="diaSemana">Selecione o dia da semana:</label>
                <select
                  name="diaSemana"
                  id="diaSemana"
                  className={style.select}
                  onChange={selectDiaSemana}
                >
                  <option value="">Selecione...</option>
                  <option value="segunda">Segunda</option>
                  <option value="terca">Terça</option>
                  <option value="quarta">Quarta</option>
                  <option value="quinta">Quinta</option>
                  <option value="sexta">Sexta</option>
                  <option value="sabado">Sábado</option>
                  <option value="domingo">Domingo</option>
                </select>
              </div>
              <div className={style.fieldSeparador}>
                <label htmlFor="inicioEdit">
                  Horário de início de atendimento:
                </label>
                <input
                  type="time"
                  className=""
                  id="inicioEdit"
                  value={inicioEdit}
                  onChange={handleInicioEdit}
                />
              </div>
              <div className={style.fieldSeparador}>
                <label htmlFor="finalEdit">Horário final de atendimento:</label>
                <input
                  type="time"
                  className=""
                  id="finalEdit"
                  value={finalEdit}
                  onChange={handleFinalEdit}
                />
              </div>
              <div className={style.fieldSeparador}>
                <label htmlFor="inicioAlmocoEdit">
                  Horário de início de almoço:
                </label>
                <input
                  type="time"
                  className=""
                  id="inicioAlmocoEdit"
                  value={inicioAlmocoEdit}
                  onChange={handleInicioAlmocoEdit}
                />
              </div>
              <div className={style.fieldSeparador}>
                <label htmlFor="finalAlmocoEdit">
                  Horário final de almoço:
                </label>
                <input
                  type="time"
                  className=""
                  id="finalAlmocoEdit"
                  value={finalAlmocoEdit}
                  onChange={handleFinalAlmocoEdit}
                />
              </div>
              <div className={style.fieldSeparador}>
                <label htmlFor="servicosEdit">Serviços:</label>

                <p>Selecione os serviços que deseja incluir:</p>

                <ul className="servicosFuncionario">
                  {listaServicos.length > 0 &&
                    listaServicos.map((servico) => {
                      if (servico.selecionado === "selecionado") {
                        return (
                          <li
                            id={servico.id}
                            className="selecionado"
                            data-name={servico.servico}
                            data-duracao={servico.duracaoMinutos}
                            data-preco={servico.preco}
                            onClick={selecionaServico}
                          >
                            {servico.servico}
                          </li>
                        );
                      } else {
                        return (
                          <li
                            id={servico.id}
                            data-name={servico.servico}
                            data-duracao={servico.duracaoMinutos}
                            data-preco={servico.preco}
                            onClick={selecionaServico}
                          >
                            {servico.servico}
                          </li>
                        );
                      }
                    })}
                </ul>

                <button
                  className="button limpar-selecao servicos"
                  onClick={limparServicosSelecionados}
                >
                  Limpar serviços selecionados
                </button>
              </div>

              <button
                className="button salvar-servico"
                data-edit={idDeleted}
                onClick={salvarEdicao}
              >
                Salvar
              </button>
            </div>
          )}
        </>
      )}
    </main>
  );
};

export default Funcionarios;
