import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CurrencyInput } from "react-currency-mask";
import InputMask from "react-input-mask";
import s from "./style.module.scss";
import Modal from "components/Modal";
import API from "config/configs";
import checkIsLogged from "components/IsLogged";

const Configuracoes = () => {
  const administrador = "lksribeiro2014@gmail.com";

  const [data, setData] = useState("");
  const [horario, setHorario] = useState("");
  const [horarioLista, setHorarioLista] = useState([]);
  const [menorDuracao, setMenorDuracao] = useState("");
  const [mensagemModal, setMensagemModal] = useState({});
  const [showSelectHorarios, setShowSelectHorarios] = useState(false);
  const [listaHorariosAusentes, setListaHorariosAusentes] = useState([]);
  let [itensFormasPagamento, setItensFormasPagamento] = useState([]);
  const [endereco, setEndereco] = useState("");
  const [horarioInicialAtendimento, setHorarioInicialAtendimento] =
    useState("");
  const [horarioFinalAtendimento, setHorarioFinalAtendimento] = useState("");
  const [horarioInicioAlmoco, setHorarioInicioAlmoco] = useState("");
  const [horarioFinalAlmoco, setHorarioFinalAlmoco] = useState("");
  const [googleMaps, setGoogleMaps] = useState("");
  const [insta, setInstagram] = useState("");
  const [face, setFace] = useState("");
  const [whats, setWhats] = useState("");
  const [diasAtendimento, setDiasAtendimento] = useState("");
  const [limitDias, setLimitDias] = useState("");
  const [fieldPreco, setFieldPreco] = useState("");
  const [showConteudo, setShowConteudo] = useState(false);
  const [showConteudo2, setShowConteudo2] = useState(false);
  const [showConteudo3, setShowConteudo3] = useState(false);
  const [showConteudo4, setShowConteudo4] = useState(false);
  let [corTema, setCorTema] = useState("");
  const [nomeTopoSite, setNomeTopoSite] = useState("");
  let [fotoSite, setFotoSite] = useState("");
  const [listaServicos, setListaServicos] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [listaServicosSelecionados, setListaServicosSelecionados] = useState(
    []
  );
  const [funcionarioSelecionado, setFuncionarioSelecionado] = useState([]);
  let [funcionarioLista, setFuncionarioLista] = useState([]);
  const [diaSemana, setDiaSemana] = useState("");
  const [selectFuncionario, setSelectFuncionario] = useState("");
  const [diasCadastrados, setDiasCadastrados] = useState([]);
  const [diaSemanaHoje, setDiaSemanaHoje] = useState("");
  let [showFieldFuncionario, setShowFieldFuncionario] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const checkIsAdmin = async () => {
    const isLoggedIn = JSON.parse(localStorage.getItem("isLogged") || "null");
    if (!isLoggedIn) return;

    const response = await checkIsLogged();
    const isAdminUser = response.some(
      (user) =>
        user.usuario === isLoggedIn.login &&
        user.senha === isLoggedIn.senha &&
        user.usuario === "lksribeiro2014@gmail.com"
    );

    if (isAdminUser) {
      setIsAdmin(true);
    }
  };

  const handleEndereco = (e) => setEndereco(e.target.value);
  const handleHorarioInicialAtendimento = (e) =>
    setHorarioInicialAtendimento(e.target.value);
  const handleGoogleMaps = (e) => setGoogleMaps(e.target.value);
  const handleInstagram = (e) => setInstagram(e.target.value);
  const handleFace = (e) => setFace(e.target.value);
  const handleWhats = (e) => setWhats(e.target.value);
  const handleDiasAtendimento = (e) => setDiasAtendimento(e.target.value);
  const handleLimitDias = (e) => setLimitDias(e.target.value);

  // start handles click e onchange
  const handleHorario = (e) => {
    let item = e.target.value;
    setHorario(item);

    const hasItem = listaHorariosAusentes.find((element) => element === item);

    if (!hasItem && item.indexOf("atendimento agendado") === -1) {
      setListaHorariosAusentes([...listaHorariosAusentes, item]);
    }
  };

  const selecionaServico = (e) => {
    let id = e.target.id;
    let nome = e.target.dataset.name;
    let duracaoMinutos = e.target.dataset.duracao;
    let preco = e.target.dataset.preco;

    let servico = {
      id: id,
      servico: nome,
      duracaoMinutos,
      preco,
      selecionado: "selecionado",
    };

    document.getElementById(id).classList.add("selecionado");

    // não permite itens duplicados - se item não existe na lista
    if (
      !listaServicosSelecionados.some(
        (objeto) =>
          objeto.id === servico.id && objeto.servicoNome === servico.servicoNome
      )
    ) {
      setListaServicosSelecionados([...listaServicosSelecionados, servico]);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();

      if (selectedFile) {
        formData.append("image", selectedFile);

        const response = await fetch(`${API}/upload`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();

          let foto = data.filePath;
          foto = foto.replace(/\\/g, "/");

          let id = 1;
          let endereco = document.getElementById("endereco").value;
          let googleMaps = document.getElementById("googleMaps").value;
          let instagram = document.getElementById("instagram").value;
          let whatsapp = document.getElementById("whatsapp").value;
          let facebook = document.getElementById("facebook").value;
          let atendimento = document.getElementById("atendimento").value;
          let limitadorDias = document.getElementById("limitadorDias").value;
          let tituloHeader = document.getElementById("nomeTopoSite").value;
          let corTemaValue = corTema;

          if (googleMaps.indexOf('src="') !== -1) {
            googleMaps = googleMaps.split('src="')[1];
            googleMaps = googleMaps.split('" width="')[0];
          }

          if (tituloHeader === "") tituloHeader = "Agenda";
          if (endereco === "")
            endereco = "Seu endereço, São Paulo - SP - Brasil";
          if (corTemaValue === "" || corTema === null) corTemaValue = "#1e88e5";
          if (foto === "") foto = "assets/logoDefault.png";
          if (
            itensFormasPagamento.length === 0 ||
            itensFormasPagamento[0] === ""
          )
            itensFormasPagamento = "Pix, Cartão de crédito e Débito, Dinheiro";
          if (whatsapp === "") whatsapp = "(11) 99999-9999";

          let body = {
            id: id,
            endereco: endereco,
            googleMaps: googleMaps,
            formasPagamento: `${itensFormasPagamento}`,
            Instagram: instagram,
            Facebook: facebook,
            WhatsApp: whatsapp,
            DiasAtendimento: atendimento,
            corTema: corTemaValue,
            limitadorDias: limitadorDias,
            tituloHeader: tituloHeader,
            fotoLogo: foto,
          };

          fetch(`${API}/configuracoes`, {
            method: "put",
            body: JSON.stringify(body),
            headers: { "Content-type": "application/json; charset=UTF-8" },
            async: false,
          });

          setMensagemModal({
            text: "Imagem enviada com sucesso",
            status: "sucesso",
          });

          if (foto.indexOf("assets") !== -1) {
            foto = foto.split("assets/")[1];
            setFotoSite(`assets/${foto}`);
          }
        } else {
          console.error(
            "Erro na solicitação:",
            response.status,
            response.statusText
          );
        }
      } else {
        console.error("Nenhum arquivo selecionado.");
      }
    } catch (error) {
      console.error("Erro durante o upload:", error);
    }
  };

  const handleNomeTopoSite = (e) => {
    setNomeTopoSite(e.target.value);
  };

  const handleCorTema = (e) => {
    let itemLista = document.querySelectorAll(`.corTemaLista li`);

    itemLista.forEach((item) => {
      item.classList.remove("cor-selected");
    });

    document
      .querySelector(`.${e.target.className}`)
      .classList.add("cor-selected");

    let cor = `#${e.target.className.split("cor-")[1]}`;
    cor = cor.trim();
    setCorTema(cor);
  };

  const handleAllHorarios = (e) => {
    let listTemp = [];

    horarioLista.map((item, index) => {
      let horario = item.hora;

      if (horario.indexOf("atendimento agendado") === -1) {
        listTemp.push(horario);

        setListaHorariosAusentes(listTemp);
      }
    });
  };

  const limparHorariosSelecionados = (e) => {
    setListaHorariosAusentes([]);
  };

  const limparFormasPagamento = (e) => {
    setItensFormasPagamento([]);
  };

  const handleFuncionario = (e) => {
    let dados = "";

    setHorario("");
    setShowSelectHorarios(true);
    setListaHorariosAusentes([]);

    if (e.target.value !== "") {
      dados = JSON.parse(e.target.value);
      setFuncionarioSelecionado(dados);

      funcionarioLista.map((funcionario) => {
        if (dados[0].nome === funcionario.nome) {
          fetch(`${API}/agendamentos/servicos`)
            .then((res) => res.json())
            .then((servicos) => {
              let listaTemp = [];

              servicos.map((item) => {
                listaTemp.push(item);

                funcionario.servicos.map((itemFuncionario) => {
                  if (item.servico === itemFuncionario.servico) {
                    itemFuncionario.duracaoMinutos = item.duracaoMinutos;
                    item.preco = `R$ ${item.preco.split("R$")[1].trim()}`;
                    itemFuncionario.preco = item.preco;
                  }
                });
              });

              funcionario.servicos.map((servico) => {
                servico.selected = "";
              });
            });

          // console.log('funcionario selecionado: ', funcionario.nome)
          // console.log('funcionario selecionado lista: ', funcionario)
          // console.log('diaSemanaHojee--: ', diaSemanaHoje)

          if (funcionario[diaSemanaHoje]) {
            let getListaHorarios = JSON.parse(funcionario[diaSemanaHoje]);

            getListaHorarios.map((hora) => {
              setHorarioInicialAtendimento(hora.horarioInicio);
              setHorarioFinalAtendimento(hora.horarioFinal);
              setHorarioInicioAlmoco(hora.horarioInicioAlmocoFuncionario);
              setHorarioFinalAlmoco(hora.horarioFinalAlmocoFuncionario);
            });
          }
        }
      });
    } else {
      setShowSelectHorarios(false);
    }
  };

  const handleData = (e) => {
    let data = e.target.value;
    data = data.replace(/\//g, "-"); // substitui eventuais barras (ex. IE) "/" por hífen "-"

    let data_array = data.split("-"); // quebra a data em array
    let dia = data_array[2];
    let mes = data_array[1];
    let ano = data_array[0];

    // para o IE onde será inserido no formato dd/MM/yyyy
    if (data_array[0].length != 4) {
      dia = data_array[0];
      mes = data_array[1];
      ano = data_array[2];
    }

    var hoje = new Date();
    var d1 = hoje.getDate();
    var m1 = hoje.getMonth() + 1;
    var a1 = hoje.getFullYear();

    var d1 = new Date(a1, m1, d1);
    var d2 = new Date(ano, mes, dia);

    var diff = d2.getTime() - d1.getTime();
    diff = diff / (1000 * 60 * 60 * 24);

    if (diff < 0) {
      console.log("Data não pode ser anterior ao dia de hoje!");

      setMensagemModal({
        text: "Data não pode ser anterior ao dia de hoje!",
        status: "erro",
      });
    } else {
      setData(e.target.value);
      setListaHorariosAusentes([]);
      setShowFieldFuncionario(false);

      let data = e.target.value;
      let ano = data.split("-")[0];
      let mes = Number(data.split("-")[1]);
      let dia = data.split("-")[2];

      data = `${ano}-${mes}-${dia}`;

      const newData = new Date(data);
      getFuncionarios(newData);
    }
  };

  const getFuncionarios = (newData) => {
    let newDataValue = newData;

    fetch(`${API}/agendamentos/funcionarios`)
      .then((res) => res.json())
      .then((funcionarios) => {
        let listaTemp = [];
        let dataHoje = "";

        // console.log('newData', newDataValue)

        if (newDataValue) {
          dataHoje = new Date(newDataValue);
        } else {
          dataHoje = new Date();
        }

        const diaSemana = dataHoje.getDay();

        const DiasDaSemana = {
          0: "domingo",
          1: "segunda",
          2: "terca",
          3: "quarta",
          4: "quinta",
          5: "sexta",
          6: "sabado",
        };

        let semanaDia = DiasDaSemana[diaSemana];
        setDiaSemanaHoje(semanaDia);

        funcionarios.map((funcionario) => {
          let nome = funcionario.nomeFuncionario;
          let id = funcionario.id;
          let servicos = JSON.parse(funcionario.servicos);
          let segunda = funcionario.segunda;
          let terca = funcionario.terca;
          let quarta = funcionario.quarta;
          let quinta = funcionario.quinta;
          let sexta = funcionario.sexta;
          let sabado = funcionario.sabado;
          let domingo = funcionario.domingo;

          // console.log('diaSemanaHoje**: ', semanaDia)
          // console.log('funcionario: ', funcionario)
          // console.log('funcionario[diaSemanaHoje]: ', funcionario[semanaDia])

          // se o funcionario atende no dia da semana de hoje exibe ele na lista
          if (funcionario[semanaDia]) {
            listaTemp.push({
              id,
              nome,
              servicos,
              segunda,
              terca,
              quarta,
              quinta,
              sexta,
              sabado,
              domingo,
            });
          }
        });

        if (listaTemp.length === 0) {
          setMensagemModal({
            text: "Não existem funcionários disponíveis para o dia selecionado!",
            status: "erro",
          });
        } else {
          setFuncionarioLista(listaTemp);
          setShowFieldFuncionario(true);
        }
      });
  };

  const listaHorarios = () => {
    // cria lista horarios default
    // menorDuracao = 10 minutos cada serviço

    // start configs
    let horaInicial = Number(horarioInicialAtendimento.split(":")[0]);
    let horaInicialMinutos = Number(horarioInicialAtendimento.split(":")[1]);
    let horaFinal = Number(horarioFinalAtendimento.split(":")[0]); // fecha as 19hs
    let horaFinalMinutos = Number(horarioFinalAtendimento.split(":")[1]);

    // almoço
    let horaInicialAlmoco = Number(horarioInicioAlmoco.split(":")[0]);
    let minutosInicialAlmoco = Number(horarioInicioAlmoco.split(":")[1]);
    let horaFinalAlmoco = Number(horarioFinalAlmoco.split(":")[0]);
    let minutosFinalAlmoco =
      Number(horarioFinalAlmoco.split(":")[1]) - menorDuracao;
    // end configs

    let lista = [];
    let minutos = 0;
    let minutosTotal = 60;

    let contadorMinutos = 0;

    // gera hora inicial ate hora final
    for (let iHora = horaInicial; iHora <= horaFinal; iHora++) {
      // inicia contagem de minutos a partir do minuto inicial ex: 09:30
      if (iHora === horaInicial) {
        minutos = horaInicialMinutos;
      } else if (iHora === horaFinal) {
        minutosTotal = horaFinalMinutos;
      } else {
        minutos = 0;
      }

      // gera minutos pulando pela quantidade de minutos do serviço de menor duração
      for (
        let iMinutos = minutos;
        iMinutos < minutosTotal;
        iMinutos += menorDuracao
      ) {
        // setando info de horario de almoço 12:00 - 12:50
        if (
          iHora === horaInicialAlmoco &&
          iMinutos >= minutosInicialAlmoco &&
          iMinutos <= minutosTotal
        ) {
          if (iMinutos === 0) {
            lista.push({
              hora: `${iHora}:0${iMinutos}`,
              qtdMinutosItem: contadorMinutos,
              status: "em horário de almoço",
            });
          } else {
            lista.push({
              hora: `${iHora}:${iMinutos}`,
              qtdMinutosItem: contadorMinutos,
              status: "em horário de almoço",
            });
          }
          // setando info de horario de almoço 13:00 - 13:30
        } else if (
          iHora === horaFinalAlmoco &&
          iMinutos <= minutosFinalAlmoco
        ) {
          if (iMinutos === 0) {
            lista.push({
              hora: `${iHora}:0${iMinutos}`,
              qtdMinutosItem: contadorMinutos,
              status: "em horário de almoço",
            });
          } else {
            lista.push({
              hora: `${iHora}:${iMinutos}`,
              qtdMinutosItem: contadorMinutos,
              status: "em horário de almoço",
            });
          }
        } else if (iMinutos === 0) {
          lista.push({
            hora: `${iHora}:0${iMinutos}`,
            qtdMinutosItem: contadorMinutos,
          });
        } else {
          lista.push({
            hora: `${iHora}:${iMinutos}`,
            qtdMinutosItem: contadorMinutos,
          });
        }

        contadorMinutos = contadorMinutos + 10;
      }
    }

    setHorarioLista(lista);

    return lista;
  };

  const getAgendamentos = (dataChange) => {
    if (dataChange) {
      setData(dataChange);
    }

    let body = {
      agendamentoData: `${dataChange}`,
    };

    fetch(`${API}/agendamentos`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => {
        // se tiver algum horario indisponivel no dia selecionado
        if (data.length) {
          let listaInicialHorarios = listaHorarios();

          data.map((agendamento) => {
            let agendamentoHorario = agendamento.horario;
            let agendamentoServicosSoma = "";
            let indexAtendimento = "";

            if (agendamento.funcionario === funcionarioSelecionado[0].nome) {
              // start info de atendimento agendado da lista
              listaInicialHorarios.map((horario, index) => {
                if (horario.hora === agendamentoHorario) {
                  let agendamentoServicos = JSON.parse(agendamento.servicos);
                  agendamentoServicosSoma = agendamentoServicos.reduce(
                    (a, b) => a + parseFloat(b.duracao),
                    0
                  );
                  agendamentoServicosSoma =
                    agendamentoServicosSoma / menorDuracao;
                  indexAtendimento = index;

                  let indexMarcar = indexAtendimento + agendamentoServicosSoma;
                  for (let i = indexAtendimento; i < indexMarcar; i++) {
                    listaInicialHorarios[
                      i
                    ].hora = `${listaInicialHorarios[i].hora} - atendimento agendado`;
                  }
                } else if (
                  agendamentoHorario.indexOf("ausente") >= 0 &&
                  agendamentoHorario.indexOf(horario.hora) >= 0
                ) {
                  horario.hora = `${horario.hora} - ausente`;
                }
              });
              // end info de atendimento agendado da lista
            }
          });

          setHorarioLista(listaInicialHorarios);
        } else {
          let listaInicialHorarios = listaHorarios();
          setHorarioLista(listaInicialHorarios);
        }
      })
      .catch((err) => {
        console.log("Erro na exibição de horários", err);

        setMensagemModal({
          text: "Erro de sistema, por favor entre em contato!",
          status: "erro",
        });
      });
  };

  const cadastrarHorarioAusente = () => {
    if (data === "") {
      setMensagemModal({ text: "Selecione uma data!", status: "erro" });
    } else if (horario === "") {
      setMensagemModal({
        text: "Selecione os horários que ficará ausente!",
        status: "erro",
      });
    }

    listaHorariosAusentes.map((horario) => {
      let body = {
        agendamentoData: `${data}`,
        horario: `${horario} - ausente`,
        servicos: "[]",
        nome: "administrador",
        telefone: "",
        celular: "",
        usuario: administrador,
        total: "",
        pago: "",
        funcionario: funcionarioSelecionado[0].nome,
      };

      fetch(`${API}/agendamentos/agendamento`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
        .then((response) => response.json())
        .then((json) => {
          setMensagemModal({
            text: "Horários que estará ausente cadastrados com sucesso!",
            status: "sucesso",
          });

          setShowFieldFuncionario(false);
          setHorario("");
          setData("");
          setShowSelectHorarios(false);
          setListaHorariosAusentes([]);
        })
        .catch((err) => {
          console.log(err);

          setMensagemModal({
            text: "Erro de sistema, por favor entre em contato!",
            status: "erro",
          });
        });
    });
  };

  const selectFormasPagamento = (e) => {
    if (e.target.value !== "") {
      if (itensFormasPagamento.indexOf(e.target.value) === -1) {
        setItensFormasPagamento([...itensFormasPagamento, e.target.value]);
      }
    }
  };

  const selectDiaSemana = (e) => {
    if (e.target.value !== "") {
      setDiaSemana(e.target.value);
    }
  };
  const selectFuncionarioDia = (e) => {
    let id = "";
    let dias = [];

    if (e.target.value !== "") {
      setSelectFuncionario(e.target.value);
      id = JSON.parse(e.target.value);
      id = Number(id[0].id);
    }

    funcionarioLista.map((funcionario) => {
      if (id === funcionario.id) {
        dias.push({ segunda: JSON.parse(funcionario.segunda) });
        dias.push({ terca: JSON.parse(funcionario.terca) });
        dias.push({ quarta: JSON.parse(funcionario.quarta) });
        dias.push({ quinta: JSON.parse(funcionario.quinta) });
        dias.push({ sexta: JSON.parse(funcionario.sexta) });
        dias.push({ sabado: JSON.parse(funcionario.sabado) });
        dias.push({ domingo: JSON.parse(funcionario.domingo) });
      }
    });

    // dias.map(dia => {
    //   if (dia.segunda) {
    //     if (dia.segunda.length && dia.segunda !== null) {
    //       dia.segunda.map(diaHora => {
    //         console.log(diaHora.horarioInicio)
    //       })
    //     }
    //   }
    // })

    setDiasCadastrados(dias);
  };

  const cadastrarServicosFuncionarios = () => {
    let nomeFuncionario = document.getElementById("nomeFuncionario").value;

    if (nomeFuncionario === "" || listaServicosSelecionados.length === 0) {
      setMensagemModal({
        text: "Nome do funcionário e serviço(s) precisam estar preenchidos",
        status: "erro",
      });
    } else {
      let body = {
        nomeFuncionario: nomeFuncionario,
        servicos: JSON.stringify(listaServicosSelecionados),
      };

      fetch(`${API}/configuracoes/horarios/funcionarios`, {
        method: "post",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json; charset=UTF-8" },
        async: false,
      });

      setMensagemModal({
        text: "Cadastro realizado com sucesso",
        status: "sucesso",
      });

      document.getElementById("nomeFuncionario").value = "";
      setListaServicosSelecionados([]);

      let elem = document.querySelectorAll(".servicosFuncionario li");
      elem.forEach((ele) => {
        ele.classList.remove("selecionado");
      });
    }
  };

  const atualizarPage = () => {
    window.location.reload();
  };

  // atualiza horario de cada dia da semana do funcionario
  const cadastrarHorariosFuncionario = () => {
    let nomeFuncionario = "";
    let dia = diaSemana;
    let segunda = "",
      terca = "",
      quarta = "",
      quinta = "",
      sexta = "",
      sabado = "",
      domingo = "";
    let horarioInicio = document.getElementById(
      "horarioInicioFuncionario"
    ).value;
    let horarioFinal = document.getElementById("horarioFinalFuncionario").value;
    let horarioInicioAlmocoFuncionario = document.getElementById(
      "horarioInicioAlmocoFuncionario"
    ).value;
    let horarioFinalAlmocoFuncionario = document.getElementById(
      "horarioFinalAlmocoFuncionario"
    ).value;
    let listaHorarios = JSON.stringify([
      {
        horarioInicio,
        horarioFinal,
        horarioInicioAlmocoFuncionario,
        horarioFinalAlmocoFuncionario,
      },
    ]);

    if (selectFuncionario !== "") {
      nomeFuncionario = JSON.parse(selectFuncionario);
      nomeFuncionario = nomeFuncionario[0].id;
    }

    if (dia === "segunda") segunda = listaHorarios;
    if (dia === "terca") terca = listaHorarios;
    if (dia === "quarta") quarta = listaHorarios;
    if (dia === "quinta") quinta = listaHorarios;
    if (dia === "sexta") sexta = listaHorarios;
    if (dia === "sabado") sabado = listaHorarios;
    if (dia === "domingo") domingo = listaHorarios;

    if (
      horarioInicio === "" ||
      horarioFinal === "" ||
      horarioInicioAlmocoFuncionario === "" ||
      horarioFinalAlmocoFuncionario === "" ||
      dia === "" ||
      nomeFuncionario === ""
    ) {
      setMensagemModal({
        text: "Todos os campos precisam estar preenchidos",
        status: "erro",
      });
    } else {
      // faz update de dias por id do funcionario
      let body = {
        id: nomeFuncionario,
        nomeFuncionario: "",
        segunda: segunda,
        terca: terca,
        quarta: quarta,
        quinta: quinta,
        sexta: sexta,
        sabado: sabado,
        domingo: domingo,
        servicos: "",
      };

      fetch(`${API}/funcionario/edit`, {
        method: "put",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json; charset=UTF-8" },
        async: false,
      });

      setMensagemModal({
        text: "Cadastro realizado com sucesso",
        status: "sucesso",
      });
    }
  };

  const cadastrarOthersInfos = () => {
    let id = 1;
    let endereco = document.getElementById("endereco").value;
    let googleMaps = document.getElementById("googleMaps").value;
    let instagram = document.getElementById("instagram").value;
    let whatsapp = document.getElementById("whatsapp").value;
    let facebook = document.getElementById("facebook").value;
    let atendimento = document.getElementById("atendimento").value;
    let limitadorDias = document.getElementById("limitadorDias").value;
    let tituloHeader = document.getElementById("nomeTopoSite").value;
    let horarioInicio = document.getElementById("horarioInicio").value;

    let corTemaValue = corTema;

    if (googleMaps.indexOf('src="') !== -1) {
      googleMaps = googleMaps.split('src="')[1];
      googleMaps = googleMaps.split('" width="')[0];
    }

    if (tituloHeader === "") tituloHeader = "Agenda";
    if (endereco === "") endereco = "Seu endereço, São Paulo - SP - Brasil";
    if (corTemaValue === "" || corTema === null) corTemaValue = "#1e88e5";
    if (fotoSite === "") fotoSite = "assets/logoDefault.png";
    if (itensFormasPagamento.length === 0)
      itensFormasPagamento = "Pix, Cartão de crédito e Débito, Dinheiro";
    if (whatsapp === "") whatsapp = "(11) 99999-9999";

    let body = {
      id: id,
      endereco: endereco,
      googleMaps: googleMaps,
      formasPagamento: `${itensFormasPagamento}`,
      Instagram: instagram,
      Facebook: facebook,
      WhatsApp: whatsapp,
      DiasAtendimento: atendimento,
      corTema: corTemaValue,
      limitadorDias: limitadorDias,
      tituloHeader: tituloHeader,
      fotoLogo: fotoSite,
      horarioInicioFuncionamento: horarioInicio,
    };

    fetch(`${API}/configuracoes`, {
      method: "put",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    });

    setMensagemModal({
      text: "Cadastro realizado com sucesso",
      status: "sucesso",
    });

    setTimeout(function () {
      atualizarPage();
    }, 1500);
  };

  const cadastrarServicos = () => {
    fetch(`${API}/agendamentos/servicos`)
      .then((res) => res.json())
      .then((servicos) => {
        let servico = document.getElementById("servico").value;
        let preco = document.getElementById("preco").value;
        let duracao = document.getElementById("duracao").value;
        let hasServico = false;

        servicos.map((item, index) => {
          if (item.servico.toLowerCase() === servico.toLowerCase()) {
            hasServico = true;
          }
        });

        if (servico === "" || preco === "" || duracao === "") {
          setMensagemModal({
            text: "Todos campos precisam estar preenchidos",
            status: "erro",
          });
        } else if (hasServico) {
          setMensagemModal({
            text: "Já existe um serviço cadastrado com esse nome!",
            status: "erro",
          });
        } else {
          let body = {
            servico: servico,
            preco: preco,
            duracaoMinutos: duracao,
          };

          fetch(`${API}/servicos`, {
            method: "post",
            body: JSON.stringify(body),
            headers: { "Content-type": "application/json; charset=UTF-8" },
            async: false,
          });

          setMensagemModal({
            text: "Cadastro realizado com sucesso",
            status: "sucesso",
          });

          document.getElementById("servico").value = "";
          document.getElementById("preco").value = "";
          document.getElementById("duracao").value = "";
          setFieldPreco("");
          getServicos();
        }
      });
  };

  const mostrarConteudo = () => {
    if (showConteudo === true) {
      setShowConteudo(false);
    } else {
      setShowConteudo(true);
    }
  };
  const mostrarConteudo2 = () => {
    if (showConteudo2 === true) {
      setShowConteudo2(false);
    } else {
      setShowConteudo2(true);
    }
  };
  const mostrarConteudo3 = () => {
    if (showConteudo3 === true) {
      setShowConteudo3(false);
    } else {
      setShowConteudo3(true);
    }
  };
  const mostrarConteudo4 = () => {
    if (showConteudo4 === true) {
      setShowConteudo4(false);
    } else {
      setShowConteudo4(true);
    }
  };
  // end handles click e onchange

  // start Ready
  const getServicos = () => {
    fetch(`${API}/configuracoes/lista/horarios`)
      .then((res) => res.json())
      .then((config) => {
        if (config.length) {
          setHorarioInicialAtendimento(config[0].horarioInicioAtendimento);
          setHorarioFinalAtendimento(config[0].horarioFinalAtendimento);
          setHorarioInicioAlmoco(config[0].horarioInicioAlmoco);
          setHorarioFinalAlmoco(config[0].horarioFinalAlmoco);
        }
      });

    fetch(`${API}/configuracoes/lista`)
      .then((res) => res.json())
      .then((config) => {
        if (config.length) {
          setEndereco(config[0].endereco);
          setGoogleMaps(config[0].googleMaps);
          setInstagram(config[0].Instagram);
          setFace(config[0].Facebook);
          setWhats(config[0].WhatsApp);
          setDiasAtendimento(config[0].DiasAtendimento);
          setLimitDias(config[0].limitadorDias);
          setCorTema(config[0].corTema);
          setNomeTopoSite(config[0].tituloHeader);
          setHorarioInicialAtendimento(config[0].horarioInicioFuncionamento);
          setHorarioFinalAtendimento(config[0].horarioFinalFuncionamento);

          if (config[0].fotoLogo !== null) {
            if (config[0].fotoLogo.indexOf("assets") !== -1) {
              let fotoName = config[0].fotoLogo.split("assets/")[1];
              setFotoSite(`assets/${fotoName}`);
            }
          }

          let listaFormasPagamento = "";
          if (config[0].formasPagamento !== null) {
            listaFormasPagamento = config[0].formasPagamento.split(",");
            setItensFormasPagamento(listaFormasPagamento);
          }
        } else {
          // valores default
          setCorTema("#1e88e5");
        }
      });

    fetch(`${API}/agendamentos/servicos`)
      .then((res) => res.json())
      .then((servicos) => {
        let listaTemp = [];
        let listaServicos = servicos;

        listaServicos.map((servico) => {
          listaTemp.push(servico);
        });

        setListaServicos(listaTemp);

        let duracaoMinutos = servicos.map((item) => {
          return item.duracaoMinutos;
        });

        let menorDuracao = Math.min.apply(Math, duracaoMinutos);
        setMenorDuracao(menorDuracao);
      });

    fetch(`${API}/agendamentos/funcionarios`)
      .then((res) => res.json())
      .then((funcionarios) => {
        let listaTemp = [];

        funcionarios.map((funcionario) => {
          let nome = funcionario.nomeFuncionario;
          let id = funcionario.id;
          let servicos = JSON.parse(funcionario.servicos);
          let segunda = funcionario.segunda;
          let terca = funcionario.terca;
          let quarta = funcionario.quarta;
          let quinta = funcionario.quinta;
          let sexta = funcionario.sexta;
          let sabado = funcionario.sabado;
          let domingo = funcionario.domingo;

          listaTemp.push({
            id,
            nome,
            servicos,
            segunda,
            terca,
            quarta,
            quinta,
            sexta,
            sabado,
            domingo,
          });
        });

        setFuncionarioLista(listaTemp);
      });
  };
  // end Ready

  useEffect(() => {
    checkIsAdmin();
    getServicos();
  }, []);

  useEffect(() => {
    setTimeout(function () {
      if (corTema !== "" && corTema !== null) {
        let cor = corTema.replace("#", "");

        if (document.querySelector(`.cor-${cor}`) !== null) {
          document.querySelector(`.cor-${cor}`).classList.add("cor-selected");
        }
      }
    }, 3300);
  }, [corTema]);

  useEffect(() => {
    getAgendamentos(data);
  }, [funcionarioSelecionado]);

  return (
    <main className={s.contentHorarios}>
      {isAdmin && (
        <>
          <Modal mensagem={mensagemModal} />

          <div className={s.contentHorarios__inner}>
            <div className={s.contentHorarios__item} onClick={mostrarConteudo}>
              <h2 className={s.titleSecao}>Cadastrar horário ausente:</h2>
              <span className={`icon ${showConteudo}`}></span>
            </div>

            {showConteudo && (
              <div className={s.contentHorarios__conteudo}>
                <h3 className={s.title}>
                  Selecione os horários que deseja ficar ausente:
                </h3>

                <p className={s.info}>
                  ** Para remover um horário marcado como ausente acesse a área{" "}
                  <Link to={`/meus-agendamentos`}> meus agendamentos</Link> para
                  fazer o cancelamento do registro.
                </p>

                <label htmlFor="dataConfig">Selecione uma data:</label>
                <input
                  type="date"
                  id="dataConfig"
                  className={s.horarios__field}
                  value={data}
                  onChange={handleData}
                />

                {showFieldFuncionario && (
                  <>
                    <label htmlFor="funcionario">
                      Selecione um funcionário:
                    </label>
                    <select
                      name="funcionarioConfig"
                      id="funcionarioConfig"
                      className={s.agendar__field}
                      onChange={handleFuncionario}
                    >
                      <option value="">Selecione um funcionário</option>
                      {funcionarioLista.map((item, index) => {
                        return (
                          <option
                            value={`[{"id": "${item.id}", "nome": "${item.nome}"}]`}
                            key={index}
                          >
                            {item.nome}
                          </option>
                        );
                      })}
                    </select>
                  </>
                )}

                {showSelectHorarios && (
                  <>
                    <label htmlFor="horarioFunc">
                      Selecione um horário disponível:
                    </label>
                    <select
                      name="horarioFunc"
                      id="horarioFunc"
                      className="horario-func"
                      value={horario}
                      onChange={handleHorario}
                    >
                      <option value="">Selecione...</option>
                      {horarioLista.map((item, index) => {
                        return (
                          <option value={item.hora} key={index}>
                            {item.hora}
                          </option>
                        );
                      })}
                    </select>

                    <div className={s.horarios__listaHorariosAusentes}>
                      <p>Horários que estará ausente:</p>
                      <ul>
                        {listaHorariosAusentes.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      </ul>
                    </div>
                  </>
                )}

                <button
                  className="button todos-horarios"
                  onClick={handleAllHorarios}
                >
                  Marcar todos horários
                </button>

                <button
                  className="button limpar-selecao"
                  onClick={limparHorariosSelecionados}
                >
                  Limpar itens selecionados
                </button>

                <button className="button" onClick={cadastrarHorarioAusente}>
                  Cadastrar
                </button>
              </div>
            )}
          </div>

          <div className={s.contentHorarios__inner}>
            <div className={s.contentHorarios__item} onClick={mostrarConteudo2}>
              <h2 className={s.titleSecao}>Cadastrar serviços:</h2>
              <span className={`icon ${showConteudo2}`}></span>
            </div>

            {showConteudo2 && (
              <div className={s.contentHorarios__conteudo}>
                <h3 className={s.title}>
                  Cadastre os serviços, preços e duração em minutos de cada
                  serviço:
                </h3>

                <div className={s.separadorField}>
                  <label htmlFor="servico">Serviço:</label>
                  <input type="text" id="servico" />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="preco">Preço:</label>
                  <CurrencyInput
                    placeholder="R$ 0,00"
                    id="preco"
                    value={fieldPreco}
                    onChangeValue={(event, originalValue, maskedValue) => {
                      setFieldPreco(maskedValue);
                    }}
                  />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="duracao">Duração em minutos:</label>
                  <input type="number" min={0} id="duracao" />
                </div>

                <Link to={`/servicos`} className="button servicos">
                  Remover Serviços
                </Link>

                <button className="button" onClick={cadastrarServicos}>
                  Cadastrar
                </button>
              </div>
            )}
          </div>

          <div className={s.contentHorarios__inner}>
            <div className={s.contentHorarios__item} onClick={mostrarConteudo3}>
              <h2 className={s.titleSecao}>Cadastrar Funcionário(s):</h2>
              <span className={`icon ${showConteudo3}`}></span>
            </div>

            {showConteudo3 && (
              <div className={s.contentHorarios__conteudo}>
                <h3 className={s.title}>
                  Cadastrar os serviços do funcionário:
                </h3>

                <div className={s.separadorField}>
                  <label htmlFor="nomeFuncionario">Nome do funcionário:</label>
                  <input type="text" id="nomeFuncionario" />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="listagemServicos">
                    Selecione os serviços prestados pelo funcionário:
                  </label>
                  <ul className="servicosFuncionario">
                    {listaServicos.map((servico) => {
                      return (
                        <li
                          id={servico.id}
                          data-name={servico.servico}
                          data-duracao={servico.duracaoMinutos}
                          data-preco={servico.preco}
                          onClick={selecionaServico}
                        >
                          {servico.servico}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <button
                  className="button"
                  onClick={cadastrarServicosFuncionarios}
                >
                  Cadastrar
                </button>

                <br />
                <h3 className={s.title}>
                  Cadastrar os horários de trabalho por dia da semana:
                </h3>

                <div className={s.separadorField}>
                  <label htmlFor="funcionarioDia">
                    Selecione um funcionário:
                  </label>
                  <select
                    name="funcionarioDia"
                    id="funcionarioDia"
                    onChange={selectFuncionarioDia}
                  >
                    <option value="">Selecione um funcionário</option>
                    {funcionarioLista.map((item, index) => {
                      return (
                        <option
                          value={`[{"id": "${item.id}", "nome": "${item.nome}"}]`}
                          key={index}
                        >
                          {item.nome}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="diaSemana">Selecione o dia da semana:</label>
                  <select
                    name="diaSemana"
                    id="diaSemana"
                    onChange={selectDiaSemana}
                  >
                    <option value="">Selecione...</option>
                    <option value="segunda">Segunda</option>
                    <option value="terca">Terça</option>
                    <option value="quarta">Quarta</option>
                    <option value="quinta">Quinta</option>
                    <option value="sexta">Sexta</option>
                    <option value="sabado">Sábado</option>
                    <option value="domingo">Domingo</option>
                  </select>
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="horarioInicioFuncionario">
                    Horário de início de atendimento:
                  </label>
                  <input type="time" id="horarioInicioFuncionario" />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="horarioFinalFuncionario">
                    Horário de final de atendimento:
                  </label>
                  <input type="time" id="horarioFinalFuncionario" />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="horarioInicioAlmocoFuncionario">
                    Horário de início de almoço:
                  </label>
                  <input type="time" id="horarioInicioAlmocoFuncionario" />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="horarioFinalAlmocoFuncionario'">
                    Horário final de almoço:
                  </label>
                  <input type="time" id="horarioFinalAlmocoFuncionario" />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="listagemServicos">Dias Cadastrados:</label>
                  <ul className="lista-trabalho">
                    {diasCadastrados.map((dia) => {
                      {
                        if (dia.segunda) {
                          return dia.segunda.map((diaHora) => {
                            return (
                              <li>
                                <strong>Segunda:</strong> Trabalho:{" "}
                                {diaHora.horarioInicio} até{" "}
                                {diaHora.horarioFinal}, Almoço:{" "}
                                {diaHora.horarioInicioAlmocoFuncionario} até{" "}
                                {diaHora.horarioFinalAlmocoFuncionario}
                              </li>
                            );
                          });
                        }
                        if (dia.terca) {
                          return dia.terca.map((diaHora) => {
                            return (
                              <li>
                                <strong>Terça:</strong> Trabalho:{" "}
                                {diaHora.horarioInicio} até{" "}
                                {diaHora.horarioFinal}, Almoço:{" "}
                                {diaHora.horarioInicioAlmocoFuncionario} até{" "}
                                {diaHora.horarioFinalAlmocoFuncionario}
                              </li>
                            );
                          });
                        }
                        if (dia.quarta) {
                          return dia.quarta.map((diaHora) => {
                            return (
                              <li>
                                <strong>Quarta:</strong> Trabalho:{" "}
                                {diaHora.horarioInicio} até{" "}
                                {diaHora.horarioFinal}, Almoço:{" "}
                                {diaHora.horarioInicioAlmocoFuncionario} até{" "}
                                {diaHora.horarioFinalAlmocoFuncionario}
                              </li>
                            );
                          });
                        }
                        if (dia.quinta) {
                          return dia.quinta.map((diaHora) => {
                            return (
                              <li>
                                <strong>Quinta:</strong> Trabalho:{" "}
                                {diaHora.horarioInicio} até{" "}
                                {diaHora.horarioFinal}, Almoço:{" "}
                                {diaHora.horarioInicioAlmocoFuncionario} até{" "}
                                {diaHora.horarioFinalAlmocoFuncionario}
                              </li>
                            );
                          });
                        }
                        if (dia.sexta) {
                          return dia.sexta.map((diaHora) => {
                            return (
                              <li>
                                <strong>Sexta:</strong> Trabalho:{" "}
                                {diaHora.horarioInicio} até{" "}
                                {diaHora.horarioFinal}, Almoço:{" "}
                                {diaHora.horarioInicioAlmocoFuncionario} até{" "}
                                {diaHora.horarioFinalAlmocoFuncionario}
                              </li>
                            );
                          });
                        }
                        if (dia.sabado) {
                          return dia.sabado.map((diaHora) => {
                            return (
                              <li>
                                <strong>Sábado:</strong> Trabalho:{" "}
                                {diaHora.horarioInicio} até{" "}
                                {diaHora.horarioFinal}, Almoço:{" "}
                                {diaHora.horarioInicioAlmocoFuncionario} até{" "}
                                {diaHora.horarioFinalAlmocoFuncionario}
                              </li>
                            );
                          });
                        }
                        if (dia.domingo) {
                          return dia.domingo.map((diaHora) => {
                            return (
                              <li>
                                <strong>Domingo:</strong> Trabalho:{" "}
                                {diaHora.horarioInicio} até{" "}
                                {diaHora.horarioFinal}, Almoço:{" "}
                                {diaHora.horarioInicioAlmocoFuncionario} até{" "}
                                {diaHora.horarioFinalAlmocoFuncionario}
                              </li>
                            );
                          });
                        }
                      }
                    })}
                  </ul>

                  <button className="button lista-dias" onClick={atualizarPage}>
                    Atualizar Lista
                  </button>
                </div>

                <Link to={`/funcionarios`} className="button servicos">
                  Editar Funcionários
                </Link>
                <button
                  className="button"
                  onClick={cadastrarHorariosFuncionario}
                >
                  Cadastrar
                </button>
              </div>
            )}
          </div>

          <div className={s.contentHorarios__inner}>
            <div className={s.contentHorarios__item} onClick={mostrarConteudo4}>
              <h2 className={s.titleSecao}>Cadastrar outras informações:</h2>
              <span className={`icon ${showConteudo4}`}></span>
            </div>

            {showConteudo4 && (
              <div className={s.contentHorarios__conteudo}>
                <h3 className={s.title}>
                  Cadastre o endereço, formas de pagamento, redes sociais,
                  contato, limitador de dias, definir cor do tema:
                </h3>

                <div className={s.separadorField}>
                  <label htmlFor="corTema">Selecione a cor do tema:</label>
                  <ul className="corTemaLista">
                    <li className="cor-20B2AA" onClick={handleCorTema}></li>
                    <li className="cor-4682B4" onClick={handleCorTema}></li>
                    <li className="cor-000" onClick={handleCorTema}></li>
                    <li className="cor-B22222" onClick={handleCorTema}></li>
                    <li className="cor-FF69B4" onClick={handleCorTema}></li>
                  </ul>
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="nomeTopoSite">
                    Nome de exibição no cabeçalho do site:
                  </label>
                  <input
                    type="text"
                    id="nomeTopoSite"
                    value={nomeTopoSite}
                    onChange={handleNomeTopoSite}
                  />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="fotoSite">
                    Foto ou logo para exibição na tela de realizar agendamentos:
                  </label>
                  <small>
                    <strong>Tamanho:</strong> 200x200
                  </small>

                  <div className={s.foto}>
                    <img src={fotoSite} alt={nomeTopoSite} />
                  </div>

                  <form onSubmit={handleSubmit}>
                    <input
                      type="file"
                      name="file"
                      accept="image/png, image/jpeg"
                      onChange={handleFileChange}
                    />
                    <button className="button" type="submit">
                      Enviar
                    </button>
                  </form>
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="limitadorDias">
                    Defina até quantos dias a frente pode ser feito o
                    agendamento: <small>(Defina um limite acima de 0)</small>
                  </label>

                  <input
                    type="number"
                    id="limitadorDias"
                    value={limitDias}
                    placeholder="Ex: 30"
                    onChange={handleLimitDias}
                  />
                </div>

                <h3 className={s.title}>
                  Cadastre as informações para o rodapé do site:
                </h3>

                <div className={s.separadorField}>
                  <label htmlFor="endereco">Endereço:</label>
                  <input
                    type="text"
                    id="endereco"
                    value={endereco}
                    onChange={handleEndereco}
                  />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="googleMaps">
                    Código iframe do Google Maps: <small>(Opcional)</small>
                  </label>
                  <input
                    type="text"
                    id="googleMaps"
                    value={googleMaps}
                    onChange={handleGoogleMaps}
                  />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="formasPagamento">Formas de pagamento:</label>
                  <select
                    name="formasPagamento"
                    id="formasPagamento"
                    onChange={selectFormasPagamento}
                  >
                    <option value="">Selecione as formas de pagamento:</option>
                    <option value="Pix">Pix</option>
                    <option value="Cartão de crédito e Débito">
                      Cartão de crédito e Débito
                    </option>
                    <option value="Dinheiro">Dinheiro</option>
                  </select>
                </div>

                <div className={s.separadorField}>
                  <div>
                    <label>Itens selecionados:</label>
                    <ul className={s.listFormasPagamento}>
                      {itensFormasPagamento.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>

                    <button className="button" onClick={limparFormasPagamento}>
                      Limpar itens selecionados
                    </button>
                  </div>
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="instagram">
                    Instagram: <small>(Opcional)</small>
                  </label>
                  <input
                    type="text"
                    id="instagram"
                    value={insta}
                    onChange={handleInstagram}
                  />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="facebook">
                    Facebook: <small>(Opcional)</small>
                  </label>
                  <input
                    type="text"
                    id="facebook"
                    value={face}
                    onChange={handleFace}
                  />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="whatsapp">WhatsApp:</label>
                  <InputMask
                    type="text"
                    id="whatsapp"
                    mask="(99) 99999-9999"
                    value={whats}
                    onChange={handleWhats}
                  />
                </div>

                <div className={s.separadorField}>
                  <label htmlFor="atendimento">
                    Dias da semana de funcionamento: <small>(Opcional)</small>
                  </label>
                  <input
                    type="text"
                    id="atendimento"
                    placeholder="Ex: Terça à Sábado"
                    value={diasAtendimento}
                    onChange={handleDiasAtendimento}
                  />
                </div>

                <br />
                <small>(Opcional)</small>
                <div className={s.separadorField}>
                  <label htmlFor="horarioInicio">
                    Horário de funcionamento:{" "}
                  </label>
                  <input
                    type="text"
                    id="horarioInicio"
                    value={horarioInicialAtendimento}
                    onChange={handleHorarioInicialAtendimento}
                  />
                </div>

                <button className="button" onClick={cadastrarOthersInfos}>
                  Cadastrar
                </button>
              </div>
            )}
          </div>

          <div className={s.contentHorarios__inner}>
            <Link
              to="https://sistemasflex.com.br/planos-ativos-c145135764415"
              target="_blank"
            >
              <div className={s.contentHorarios__item}>
                <h2 className={s.titleSecao}>Consultar status do meu plano</h2>
              </div>
            </Link>
          </div>
        </>
      )}
    </main>
  );
};

export default Configuracoes;
