import { useEffect, useState } from "react";
import s from "./style.module.scss";
import iconWhats from "./assets/icone-whats.png";
import iconInsta from "./assets/insta.png";
import iconFace from "./assets/face.png";
import pix from "./assets/pix.png";
import cartao from "./assets/cartao.png";
import dinheiro from "./assets/dinheiro.png";
import API from "config/configs";
import { Link } from "react-router-dom";

const Footer = () => {
  const [itensFormasPagamento, setItensFormasPagamento] = useState([]);
  const [endereco, setEndereco] = useState("");
  const [horarioInicialAtendimento, setHorarioInicialAtendimento] =
    useState("");
  const [googleMaps, setGoogleMaps] = useState("");
  const [insta, setInstagram] = useState("");
  const [face, setFace] = useState("");
  const [whats, setWhats] = useState("");
  const [whatsEdit, setWhatsEdit] = useState("");
  const [diasAtendimento, setDiasAtendimento] = useState("");
  const [corTema, setCorTema] = useState("");

  const getServicos = () => {
    fetch(`${API}/configuracoes/lista`)
      .then((res) => res.json())
      .then((config) => {
        if (config.length) {
          setEndereco(config[0].endereco);
          setGoogleMaps(config[0].googleMaps);
          setInstagram(config[0].Instagram);
          setFace(config[0].Facebook);
          setWhats(config[0].WhatsApp);
          setCorTema(config[0].corTema);
          setDiasAtendimento(config[0].DiasAtendimento);
          setHorarioInicialAtendimento(config[0].horarioInicioFuncionamento);

          if (config[0].WhatsApp !== null) {
            let editWhats = config[0].WhatsApp.replace("-", "").replace(
              "(11) ",
              ""
            );
            editWhats = `https://api.whatsapp.com/send/?phone=5511${editWhats}&text&type=phone_number&app_absent=0`;
            setWhatsEdit(editWhats);
          }

          if (config[0].formasPagamento !== null) {
            let listaFormasPagamento = config[0].formasPagamento.split(",");
            setItensFormasPagamento(listaFormasPagamento);
          }

          // valores default quando tiver o registro id 1 com outros campos vazios
          if (config[0].corTema === null) setCorTema("#1e88e5");
          if (config[0].endereco === null)
            setEndereco("Seu endereço, São Paulo - SP - Brasil");
          if (config[0].formasPagamento === null)
            setItensFormasPagamento([
              "Pix",
              "Cartão de crédito e Débito",
              "Dinheiro",
            ]);
          if (config[0].WhatsApp === null) setWhats("(11) 99999-9999");
          if (config[0].WhatsApp === null)
            setWhatsEdit(
              "https://api.whatsapp.com/send/?phone=5511999999999&text&type=phone_number&app_absent=0"
            );
        } else {
          // valores default
          setCorTema("#1e88e5");
          setEndereco("Seu endereço, São Paulo - SP - Brasil");
          setItensFormasPagamento([
            "Pix",
            "Cartão de crédito e Débito",
            "Dinheiro",
          ]);
          setWhats("(11) 99999-9999");
          setWhatsEdit(
            "https://api.whatsapp.com/send/?phone=5511999999999&text&type=phone_number&app_absent=0"
          );
        }
      });
  };

  useEffect(() => {
    getServicos();
  }, []);

  return (
    <footer className={s.footer} style={{ background: corTema }}>
      <div className={s.footer__container}>
        <div className={s.footer__top}>
          <ul>
            <li>
              <h3>Endereço</h3>
              <p>
                <strong>Rua: </strong>
                {endereco}
              </p>
              <iframe src={googleMaps}></iframe>
            </li>
            <li className={s.footer__pagamento}>
              <h3>Formas de Pagamento</h3>

              {itensFormasPagamento.map((item) => {
                if (item.indexOf("Pix") !== -1) {
                  return (
                    <p>
                      <img src={pix} alt="Pix" />
                      {item}
                    </p>
                  );
                }
                if (item.indexOf("Cartão") !== -1) {
                  return (
                    <p>
                      <img src={cartao} alt="Cartão de crédito e débito" />
                      {item}
                    </p>
                  );
                }
                if (item.indexOf("Dinheiro") !== -1) {
                  return (
                    <p>
                      <img src={dinheiro} alt="Dinheiro" />
                      {item}
                    </p>
                  );
                }
              })}
            </li>
            <li>
              <h3>Contato</h3>
              {whatsEdit && (
                <div className={s.footer__whats}>
                  <p>
                    <Link to={whatsEdit} target="_blank">
                      <img src={iconWhats} alt="WhatsApp" />
                      {whats}
                    </Link>
                  </p>
                </div>
              )}
              {diasAtendimento && (
                <p>
                  <strong>Atendimento:</strong> {diasAtendimento}
                </p>
              )}
              {horarioInicialAtendimento && (
                <p className={s.horarios}>
                  <strong>Horário:</strong> {horarioInicialAtendimento}
                </p>
              )}
            </li>
            <li className={s.footer__redes}>
              {(insta || face) && <h3>Redes Sociais</h3>}
              {insta && (
                <p>
                  <Link to={insta} target="_blank">
                    <img src={iconInsta} alt="Instagram" />
                    Instagram
                  </Link>
                </p>
              )}
              {face && (
                <p>
                  <Link to={face} target="_blank">
                    <img src={iconFace} alt="Facebook" />
                    Facebook
                  </Link>
                </p>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className={s.footer__bottom}>
        <p>
          <Link to="https://sistemasflex.com.br/" target="_blank">
            <strong>&copy; Sistemas Flex</strong> - CNPJ: 37.810.374/0001-82 -
            Todos os direitos reservados. &nbsp;
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
